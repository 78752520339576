import React from 'react'
import { Link } from 'gatsby'
import { Container, Row } from 'reactstrap'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import CareLogo from '../../assets/cpc-favicon.png'

const NotFoundPage = () => (
	<Layout>
		<SEO title='404' />
		<Container>
			<Row className='justify-content-around'>
				<img src={CareLogo} alt='' className='not-found-image' />
			</Row>
			<Row className='my-3 justify-content-around not-found-text'>You just hit a page that doesn&#39;t exist...the sadness.</Row>
			<Row className='my-3 not-found-text justify-content-around'>
				<p>
					Click&nbsp;<Link to='/'>here</Link>&nbsp;to get a smile or&nbsp;<Link to='/create'>here</Link>&nbsp;to give a smile!
				</p>
			</Row>
		</Container>
	</Layout>
)

export default NotFoundPage
